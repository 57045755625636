import axios from 'axios';
import GetFormData from "../lib/GetFormData";

const developerMode = (window.location.href.search('localhost') > -1) || (window.location.href.search('192.168.') > -1);
let Server = "http://localhost:7290/api/";
if (!developerMode) {
    // Server = "https://server.safarasa.com:7290/api/";
    Server = "https://server.qeshminfo.com:7290/api/";
}
export const isDevMode = function () {
    return developerMode;
}
export const GetwsServerAdress = () => {

    if (developerMode) {
        return "ws://localhost:7290/" + sessionStorage.getItem('token');
    } else {
        // return "wss://server.safarasa.com:7290/" + sessionStorage.getItem('token');
        return "wss://server.qeshminfo.com:7290/" + sessionStorage.getItem('token');
    }
}
export const GetServerAdress = () => {
    return Server;
}
export const GetRootServerAddress = () => {
    return Server.substring(0, Server.length - 4);
}
export const GetImageServerAdress = () => {
    var Server = "http://localhost:7290/";
    if (!developerMode) {
        // Server = "https://server.safarasa.com:7290/";
        Server = "https://server.qeshminfo.com:7290/";
    }
    return Server;
}
//////////////////////////////
export const GetPrograms = async () => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "programs", {
        Token
    });
    var B = await axios.post(Server + "baskets", {
        Token
    });
    R.data.Basket = B.data.basket;
    return R.data;
}
export const GetProvider = async () => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getPrvider", {
        Token
    });
    return R.data;
}
export const GetSiteLanguage = async () => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getSiteLang", {
        Token,
        fistRequest: true
    });
    return R.data;
}
export const GetHousesList = async (id) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "/realestate/getHouse", {
        Token,
        id
    });
    return R.data;
}
export const GetHotels = async () => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "hotels", {
        Token
    });
    var B = await axios.post(Server + "baskets", {
        Token
    });
    R.data.Basket = B.data.basket;
    return R.data;
}


export const RegisterAgency = async (formData) => {
    var R = await axios.post(Server + "registeragency", formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "apiversion": 1402
        }
    });
    return R.data;
}
export const LoginUser = async (mobileno, password, remember, role) => {
    var R = await axios.post(Server + "login", {
        mobileno: mobileno.trim(),
        password, remember, role,
        type: 'login',
    },);
    return R.data;
}
export const DeleteBasket = async (basketid) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "deletebasket", {
        Token,
        basketid,
    });
    return R.data;
}
export const ChnageSeanceCount = async (amount, basketid, itemid) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "updateitemcount", {
        Token,
        basketid,
        amount,
        itemid
    });
    return R.data;
}
export const RemoveItemFromBasket = async (basketid, itemid) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "removefrombasket", {
        Token,
        basketid,
        itemid
    });
    return R.data;
}
export const GetBasket = async (basketid) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "basketinfo", {
        Token,
        basketid,
    });
    return R.data;
}
export const GetBasket_ByBasket = async (basketid) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "basketinfo_ByBasket", {
        Token,
        basketid,
    });
    return R.data;
}

export const GetBasketHotel = async (basketid) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "basketinfoHotel", {
        Token,
        basketid,
    });
    return R.data;
}
export const GetClosedBaskets = async (data) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "closedbaskets", {
        Token,
        data,
    });
    return R.data;
}
export const GetList = async (Name, data, Params) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "get" + Name + "list", {
        Token,
        data,
        Params
    });
    return R.data;
}
export const DelItem = async (Name, id, Params) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "del" + Name, {
        Token,
        id, Params,
    });
    return R.data;
}
export const GetEntity = async (Name, id = undefined, form) => {
    const formData = await GetFormData(form, id);
    var R = await axios.post(Server + "get" + Name, formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
        }
    });
    return R.data;
}
export const GetHouses = async (Name, id, form) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "get" + Name, {
        Token,
        id,
        form,
    });
    return R.data;
}
export const GetBrandClass = async (brand) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getBrandClass", {
        Token,
        brand
    });
    return R.data;
}
export const GetWebSiteByUrl = async (webSiteUrl) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getWebSiteByUrl", {
        Token,
        webSiteUrl
    });
    return R.data;
}

export const UploadProgramPhotos = async (form, filename) => {

    const R = await axios.post(Server + "uploadProgramPhotos", await GetFormData(form, null, 'photo'),
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
            }
        })

    return R.data;
}
export const AddEditEntitty = async (Name, form) => {
    var R = await axios.post(Server + "addedit" + Name, await GetFormData(form), {
        headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
        }
    });
    return R.data;
}
export const AddEditEntitty_new = async (Name, form, id = '') => {
    const formData = await GetFormData(form, id);
    var R = await axios.post(Server + "addedit" + Name, formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
        }
    });
    return R.data;
}
export const AddEditEntitty_new2 = async (Name, formData) => {
    var R = await axios.post(Server + "addedit" + Name, Object.fromEntries(formData), {
        headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
        }
    });
    return R.data;

    /*   const formData = await GetFormData(form,id);
       var R = await axios.post(Server + "addedit" + Name, formData, {
           headers: {
               Accept: "application/json",
               "Content-Type": "multipart/form-data",
           }
       });
       return R.data;*/
}
export const GetPayment = async (type, price, detail) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getchargepayment", {
        Token,
        type,
        price, detail, host: window.location.origin
    });

    return R.data;
}
export const GetBankinfo = async (payid) => {
    //alert(payid)
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "handshakebank", {
        Token,
        payid
    });
    //alert(R)
    return R.data;
}
export const Getcharge = async (payid) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getcharge", {
        Token,
    });
    return R.data;
}
export const GetProfile = async (form) => {
    /* let Token = sessionStorage.getItem('token');
     var R = await axios.post(Server + "profile", {
         Token,
     });
     return R.data;*/
    const formData = await GetFormData(form);
    formData.append('fistRequest', true)
    var R = await axios.post(Server + "profile", formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
        }
    });
    return R.data;

}
export const GetPanelSetting = async () => {
    //alert(">>1")
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getPanelSetting", {
        Token,
    });
    //alert(">>2")

    return R.data;
}
export const UpdatePanelSetting = async (frm) => {

    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "updatePanelSetting", {
        Token,
        data: frm
    });
    return R.data;
}

export const GetUploadPath = async () => {
    let Token = sessionStorage.getItem('token');

    var R = await axios.post(Server + "getuploadpath", {
        Token,
    });
    return R.data;
}
export const UpdateProfile = async (form) => {
    /*let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "updateprofile", {
        Token,
        data: frm
    });
    return R.data;
    */
    const formData = new FormData();
    Object.entries(form).map((e) => {
        formData.append(e[0], e[1])
    })
    let Token = sessionStorage.getItem('token');
    await formData.append('Token', Token);
    var R = await axios.post(Server + "updateprofile", formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
        }
    });
    return R.data;
}
export const ChangePassword = async (frm) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "changepassword", {
        Token,
        data: frm
    });
    return R.data;
}

export const GetOfferReportParams = async () => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getofferreportparameters", {
        Token,
    });
    return R.data;
}
export const GetSellReportParams = async () => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getsellreportparameters", {
        Token,
    });
    return R.data;
}
export const GetSeanceReportParams = async () => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getSeanceReportParams", {
        Token,
    });
    return R.data;
}
export const GetCancelRequestParams = async () => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getcancelrequestparameters", {
        Token,
    });
    return R.data;
}
export const GetManifestParams = async () => {

    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getmanifestparameters", {
        Token,
    });
    return R.data;
}
export const GetManifestSeances = async (programid, date) => {

    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getManifestSeances", {
        Token, programid, date
    });
    return R.data;
}
export const GetQuicSeances = async (pid, statstics = false, date = "", fromFront = false) => {

    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "quickseances", {
        Token,
        pid, statstics, date, fromFront
    });

    return R.data;
}
export const GetQuicSeances_turkish = async (pid, statstics = false, date = "") => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "quickseances_turkish", {
        Token,
        pid, statstics, date
    });
    return R.data;
}
export const GetQuicHotelPrice = async (pid, statstics = false, date = "") => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "quickHotelPrice", {
        Token,
        pid, statstics, date
    });
    return R.data;
}
export const GetRoomPrice = async (pid, statstics = false, date = "") => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getRoomPrice", {
        Token,
        pid, statstics, date
    });
    return R.data;
}
export const QuickBuy = async (name, phone, count, offer, Seanceid, Day, gift, curdetail, Exs, hotelName, hotelVoucher, roomNumber, note, Discount) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "quickbuy", {
        Token,
        name,
        phone,
        count,
        offer,
        id: Seanceid,
        date: Day,
        gift, curdetail, Exs,
        hotelName,
        hotelVoucher,
        roomNumber,
        note,
        Discount
    });
    return R.data;
}
export const QuickBuy_byBasket = async (cart) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "quickbuy_ByBasket", {
        Token, cart
    });
    return {
        status: R.data.Status,
        data: R.data
    }
}
export const QuickBuyHoel = async (roomPriceID, fromDate, toDate, roomCount, passengerCount
    , name, phone, codeMeli, numDays, allPrice, commision, agencyPrice) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "quickbuyHotel", {
        Token,
        roomPriceID, fromDate, toDate, roomCount, passengerCount
        , name, phone, codeMeli, numDays, allPrice, commision, agencyPrice

    });
    return R.data;
}
export const FinalizeQuickBuy = async (price, Seanceid, Day, count, offer, name, phone, gift, giftid, Extras, curdetail, Discount, hotelName
    , hotelVoucher, roomNumber, note) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "finalizequickbuy", {
        Token,
        price, Seanceid, Day, count, offer, name, phone, gift, giftid, Extras, curdetail, Discount, hotelName,
        hotelVoucher, roomNumber, note
    });
    return R.data;
}
export const FinalizeQuickBuy_byBasket = async (cart) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "finalizequickbuy_byBasket", {
        Token, cart
    }).catch((err) => {

    });
    return R.data;
}
export const FinalizeQuickBuyHotel = async (roomPriceID,
                                            fromDate,
                                            toDate,
                                            hotel,
                                            roomType,
                                            roomCount,
                                            passengerCount,
                                            numDays,
                                            allPrice,
                                            commision,
                                            agencyPrice,
                                            name,
                                            phone,
                                            codeMeli,
                                            navigate,) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "finalizequickbuyHotel", {
        Token,
        // price, Seanceid, Day, count, offer, name, phone, gift, giftid,Extras,curdetail,Discount
        roomPriceID,
        fromDate,
        toDate,
        hotel,
        roomType,
        roomCount,
        passengerCount,
        numDays,
        allPrice,
        commision,
        agencyPrice,
        name,
        phone,
        codeMeli,
        navigate,
    });
    return R.data;
}
export const GetBankPayment = async (price, seanceid, Day, cnt, offer, name, phone, gift, giftif, Extras, curdetail, Discount) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getbankpayment", {
        Token,
        price,
        seanceid,
        Day,
        cnt,
        offer, name, phone, gift, giftif, Extras, curdetail, Discount
    });

    return R.data;
}
export const GetBankPayment_byBasket = async ({cart}) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getbankpayment_byBasket", {
        Token,
        cart
    });
    return R.data;
}
export const GetBankPaymentHotel = async (price, roomPriceID, fromDate, toDate, roomCount, passengerCount
    , name, phone, codeMeli, allPrice, commision, agencyPrice) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getbankpaymentHotel", {
        price,
        Token,
        roomPriceID, fromDate, toDate, roomCount, passengerCount
        , name, phone, codeMeli, allPrice, commision, agencyPrice
    });
    return R.data;
}
export const GetLastNotifs = async (price, seanceid, Day, cnt, offer, name, phone) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getlastnotifs", {
        Token,
    });
    return R.data;
}
export const GetNotif = async (notifid) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getnotif", {
        Token,
        notifid
    });
    return R.data;
}
export const DelNotif = async (notifid) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "delnotif", {
        Token,
        id: notifid
    });
    return R.data;
}
export const RequestCancelTicket = async (id, serial) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "requestticketcancel", {
        Token,
        id, serial
    });
    return R.data;
}
export const GetAgencies = async () => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getagenciesofprovider", {
        Token,
    });
    return R.data;
}
export const CancelTicket = async (id, serial, price, detail) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "cancelticket", {
        Token, serial, price, detail, id
    });
    return R.data;
}
export const CancelIgnoreTicket = async (id, serial, detail, rid) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "ignorecancelticket", {
        Token, serial, detail, id, rid
    });
    return R.data;
}
export const ChangeAgencyGroup = async (agency, group) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "setagencygroup", {
        Token, agency, group
    });
    return R.data;
}
export const ChangeAgencyContext = async (agency, context) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "setagencyContext", {
        Token, agency, context
    });
    return R.data;
}
export const ChangeAgencyOffer = async (agency, offer) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "setagencyoffer", {
        Token, agency, offer
    });
    return R.data;
}

export const GetSearchTicketParams = async () => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getsearchticketparameters", {
        Token
    });
    return R.data;
}
export const InvalidateTicketParams = async () => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getinvalidateticketparameters", {
        Token
    });
    return R.data;
}
export const GetTicketChecklistParams = async (date) => {
    let Token = sessionStorage.getItem('token');

    var R = await axios.post(Server + "getticketchecklistparameters", {
        Token, date
    });

    return R.data;
}
export const GetChecklistRows = async (date, seance, type) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getchecklistdata", {
        Token, date, seance, type
    });
    return R.data;
}

export const InvalidateTicket = async (form) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "invalidateticket", {
        Token, form
    });

    return R.data;
}
export const OneTimePassword = async (number) => {
    var R = await axios.post(Server + "sendotp", {
        number
    });
    return R.data;
}
export const GetGuestableAgencies = async () => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getagenciesforguest", {
        Token
    });
    return R.data;
}
export const UpdateUserPerms = async (userid, config) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "updateuserperms", {
        Token, userid, config
    });
    return R.data;
}
export const GetSiteInfo = async ({fistRequest = false}) => {
    try {
        let Token = sessionStorage.getItem('token');
        var R = await axios.post(Server + "getsiteinfo",
            {
                timeout: 2000,
                url: window.location.href,
                fistRequest,
                Token
            },
            ).catch(err => {
                console.log('eeeeeeeee',err)
            return {
                data: {
                    Status: 'Error',
                    code: "ERR_NETWORK",
                    message: "در هنگام ارتباط با سرور خطایی رخ داده است"
                }
            }
        })
    } catch (error) {

        return {
            data: {
                Status: 'Error',
                code: "ERR_NETWORK2",
                message: "در هنگام ارتباط با سرور خطایی رخ داده است"
            }
        }
    }
    return R.data;
}
export const ApproveAgency = async (id, uname, pass) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "approveagency", {
        Token, id, uname, pass
    });
    return R.data;
}
export const InvalidateSerialVoucher = async (serialvoucher, date, seance) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "invalidateserialvoucher", {
        Token, serialvoucher, date, seance
    });
    return R.data;
}
export const CancelTickets = async (serials, id, reason) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "canceltickets", {
        Token, serials, id, reason
    });
    return R.data;
}
export const GetAgencyGuestRequestParams = async () => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getprogramforguestrequest", {
        Token,
    });
    return R.data;
}
export const AcceptGuestRequest = async (id, accept = true) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "acceptguestrequest", {
        Token, id, accept
    });
    return R.data;
}
export const ResendSms = async (id, phonenumber) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "resendticketlinkviasms", {
        Token, id, phonenumber
    });
    return R.data;
}
export const GetSeanceCalendar = async (id, monthindex) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getseancecalendar", {
        Token, id, monthindex
    });
    return R.data;
}
export const UpdateSeanceSetting = async (id, date, config) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "seancecalendarsetting", {
        Token, id, date, config
    });
    return R.data;
}
export const SendPaymentLinkviaSms = async (Price, Seanceid, Day, count, offer, name, phone, gift, giftid, Extras, phonenumber, curdetail, Discount) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "sendpaymentlinkinsms", {
        Token, Price, Seanceid, Day, count, offer, name, phone, gift, giftid, Extras, phonenumber, curdetail, Discount
    });
    return R.data;
}
export const ResendPaymentSmsForBasket = async (basketid, phonenumber) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "sendpaymentlinkinsmsforbasket", {
        Token, basketid, phonenumber
    });
    return R.data;
}
export const QuickBuyBasket = async (basketid) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "quickbuy", {
        Token, basketid
    });
    return R.data;
}
export const FinalizeQuickBuyBasket = async (basketid) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "finalizequickbuy", {
        Token, basketid
    });
    return R.data;
}
export const GetBankPaymentBasket = async (price, basketid) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getbankpayment", {
        Token, price, basketid
    });
    return R.data;
}
export const RemovePendingBasket = async (basketid) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "removependingbasket", {
        Token, basketid
    });
    return R.data;
}
export const ChangeSeanceOffer = async (seanceid, offer) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "setseanceoffer", {
        Token, seanceid, offer
    });
    return R.data;
}
export const GetSeanceDaySetting = async (seanceid, date) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "getseancedaysetting", {
        Token, seanceid, date
    });
    return R.data;
}
export const SaveSeanceDaySetting = async (seanceid, date, Row, Deleted) => {
    let Token = sessionStorage.getItem('token');
    var R = await axios.post(Server + "saveseancedaysetting", {
        Token, seanceid, date, Row, Deleted
    });
    return R.data;
}


//////////////////
export const Logout = () => {
    sessionStorage.setItem('token', "");
    window.location.reload(false);
}
